
import { computed, defineComponent, PropType, ref } from 'vue';

interface Tab {
  value: string;
  label: string;
}

export default defineComponent({
  name: 'BalTabs',

  props: {
    tabs: { type: Array as PropType<Tab[]>, required: true },
    modelValue: { type: String, default: '' },
    noPad: { type: Boolean, default: false },
    darkBgColor: { type: String, default: '900' },
  },

  emits: ['selected', 'update:modelValue'],

  setup(props, { emit }) {
    const activeTab = ref(props.modelValue);

    function isActiveTab(tab: Tab): boolean {
      return activeTab.value === tab.value;
    }

    function onClick(tab: Tab) {
      activeTab.value = tab.value;
      emit('selected', tab.value);
      emit('update:modelValue', tab.value);
    }

    const containerClasses = computed(() => {
      return {
        'px-4': !props.noPad,
        [`dark:bg-gray-${props.darkBgColor}`]: true,
      };
    });

    function stateClasses(tab: Tab): Record<string, boolean> {
      return {
        'dark:bg-gray-650 text-black dark:text-white rounded px-4 py-2 hover:text-blue-500 dark:border-blue-500 font-semibold':
          isActiveTab(tab),
        'hover:text-black dark:hover:text-white px-4 py-2 dark:border-gray-700 transition-colors':
          !isActiveTab(tab),
      };
    }

    return {
      activeTab,
      onClick,
      containerClasses,
      stateClasses,
    };
  },
});
