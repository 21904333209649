import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "animateContainer",
  ref: "animateContainer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    class: "relative",
    appear: "",
    css: false,
    onEnter: _ctx.enter,
    onLeave: _ctx.leave
  }, {
    default: _withCtx(() => [
      (_ctx.isVisible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "default")
          ], 512))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["onEnter", "onLeave"]))
}