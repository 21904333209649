import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, toDisplayString as _toDisplayString, createBlock as _createBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['bal-card', _ctx.cardClasses])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([
        'card-container',
        { 'overflow-y-scroll max-h-screen': _ctx.overflowYScroll },
      ])
    }, [
      (_ctx.imgSrc)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "feature",
            style: _normalizeStyle(_ctx.featureStyles)
          }, null, 4))
        : _createCommentVNode("", true),
      (!!_ctx.title || _ctx.$slots.header)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(['header', _ctx.headerClasses])
          }, [
            (!!_ctx.title)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.titleTag), {
                  key: 0,
                  textContent: _toDisplayString(_ctx.title)
                }, null, 8, ["textContent"]))
              : _createCommentVNode("", true),
            (_ctx.$slots.header)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(['header-content', _ctx.headerContentClasses])
                }, [
                  _renderSlot(_ctx.$slots, "header", {}, undefined, true)
                ], 2))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(['content', _ctx.contentClasses])
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2),
      (_ctx.$slots.footer)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(['footer', _ctx.footerClasses])
          }, [
            _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
          ], 2))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}