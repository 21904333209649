
import { defineComponent } from 'vue';

import useNotifications from '@/composables/useNotifications';

import Notification from './Notification.vue';

export default defineComponent({
  name: 'Notifications',

  components: {
    Notification,
  },

  setup() {
    // COMPOSABLES
    const { notifications } = useNotifications();

    return {
      // computed
      notifications,
    };
  },
});
