import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["name", "checked", "disabled"]
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "ml-2 text-xs dark:text-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "group bal-toggle",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
    }, [
      _createElementVNode("input", _mergeProps({
        type: "checkbox",
        name: _ctx.name,
        checked: _ctx.modelValue
      }, _ctx.$attrs, {
        disabled: _ctx.disabled,
        class: "bal-toggle-checkbox"
      }), null, 16, _hoisted_1),
      _createElementVNode("label", {
        for: _ctx.name,
        class: "bal-toggle-track"
      }, null, 8, _hoisted_2)
    ]),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true)
  ], 64))
}