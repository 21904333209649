import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "inline-flex items-start" }
const _hoisted_2 = ["value", "checked", "name", "disabled"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: "radio",
      value: _ctx.value,
      checked: _ctx.modelValue === _ctx.value,
      name: _ctx.name,
      class: _normalizeClass(['bal-radio-input', _ctx.inputClasses]),
      disabled: _ctx.disabled,
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChange(_ctx.value)))
    }, null, 42, _hoisted_2),
    (_ctx.$slots.label || _ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.name,
          class: _normalizeClass(['bal-radio-label', _ctx.labelClasses]),
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.onChange(_ctx.value)), ["prevent"]))
        }, [
          _renderSlot(_ctx.$slots, "label", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ])
        ], 10, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}