
import { defineComponent, PropType } from 'vue';

import useWeb3 from '@/services/web3/useWeb3';
import { Wallet, WalletNameMap } from '@/services/web3/web3.plugin';
export default defineComponent({
  props: {
    wallet: {
      type: String as PropType<Wallet>,
      required: true,
    },
  },
  setup(props) {
    const { connectWallet, toggleWalletSelectModal } = useWeb3();
    function handleClick() {
      connectWallet(props.wallet);
      toggleWalletSelectModal(false);
    }
    return {
      WalletNameMap,
      handleClick,
    };
  },
});
