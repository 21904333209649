
import { computed, defineComponent, PropType, ref } from 'vue';

import { RuleFunction, Rules } from '@/types';

export default defineComponent({
  name: 'BalCheckbox',

  props: {
    name: { type: String, required: true },
    modelValue: { type: Boolean, default: false },
    label: { type: String, default: '' },
    noMargin: { type: Boolean, default: false },
    rules: {
      type: Array as PropType<Rules>,
      default: () => [],
    },
    size: {
      type: String,
      default: 'md',
      validator: (val: string): boolean => ['sm', 'md', 'lg'].includes(val),
    },
    color: {
      type: String,
      default: 'blue',
      validator: (val: string): boolean => ['blue'].includes(val),
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const errors = ref([] as Array<string>);

    const hasError = computed(() => errors.value.length > 0);

    function validate(val: string | number): void {
      errors.value = [];
      props.rules.forEach((rule: RuleFunction) => {
        const result = rule(val);
        if (typeof result === 'string') errors.value.push(result);
      });
    }

    function onChange(event) {
      emit('update:modelValue', event.target.checked);
      validate(event.target.checked);
    }

    const sizeClasses = computed(() => {
      switch (props.size) {
        case 'sm':
          return 'w-4 h-4 -mt-px';
        case 'lg':
          return 'w-6 h-6';
        default:
          return 'w-5 h-5 mt-1';
      }
    });

    const textSizeClass = computed(() => {
      switch (props.size) {
        case 'sm':
          return 'text-sm';
        case 'lg':
          return 'text-lg';
        default:
          return 'text-base';
      }
    });

    const colorClasses = computed(() => {
      return `text-${props.color}-500`;
    });

    const wrapperClasses = computed(() => {
      return {
        'mb-5': !props.noMargin,
      };
    });

    const inputClasses = computed(() => {
      return {
        [sizeClasses.value]: true,
        [colorClasses.value]: true,
      };
    });

    const labelClasses = computed(() => {
      return {
        [textSizeClass.value]: true,
      };
    });

    return {
      wrapperClasses,
      inputClasses,
      labelClasses,
      validate,
      hasError,
      errors,
      onChange,
    };
  },
});
